<p-table
  [value]="tableData"
  [columns]="tableConfigData.columns"
  (onLazyLoad)="loadTableData($event)"
  [tableStyle]="getTableStyle"
  [lazy]="true"
  responsiveLayout="scroll"
  [loading]="loading"
  [globalFilterFields]="[]"
  [lazyLoadOnInit]="false"
  class="generic-table"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <ng-container *ngFor="let col of columns">
        <th [style.width]="col.width" class="header-cell-item">
          <div class="d-flex g-3 align-items-start justify-content-start">
            <div class="header-title text-align-left">
              {{ col.title }}
            </div>
            <div class="d-flex justify-content-start flex-wrap g-2 header-action pl-2">
              @if (col.sortable) {
                <div
                  data-test-id="header-cell"
                  [pSortableColumn]="col.sortable ? col.sortConfig.sortKey : ''"
                >
                  <p-sortIcon class="sort-icon pl-3" [field]="col.sortConfig.sortKey" />
                </div>
              }
              @if (col.hasColumnFilters) {
                @if (col.filterConfig.type === 'custom' && col.filterConfig.component) {
                  <app-custom-filters
                    [filterConfig]="col.filterConfig"
                    (onFilterChangeHandler)="onCustomFilterChangeHandler($event)"
                  />
                } @else {
                  <p-columnFilter
                    [type]="col.filterConfig.type"
                    [field]="col.filterConfig.fieldName"
                    [matchMode]="col.filterConfig.selectedFilter"
                    display="menu"
                    [showMatchModes]="true"
                    [showOperator]="false"
                    [showAddButton]="false"
                    [matchModeOptions]="col.filterConfig.options"
                    [showClearButton]="true"
                    [placeholder]="col.filterConfig.placeholder"
                    class="ml-auto"
                  >
                  </p-columnFilter>
                }
              }
            </div>
          </div>
        </th>
      </ng-container>

      <!-- for action menu -->
      <th *ngIf="tableConfigData.actionItems" class="extra"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item let-columns="columns">
    <tr data-test-id="table-row">
      @if (!item.key) {
        <td
          *ngFor="let column of columns"
          [style.text-align]="column.align"
          [style.width]="column.width"
        >
          <ng-container
            [ngTemplateOutlet]="value"
            [ngTemplateOutletContext]="{ item: item, column: column }"
          >
          </ng-container>
        </td>
        <td style="width: 20px; text-align: right" *ngIf="tableConfigData.actionItems">
          @if (dropdownVisibility) {
            <p-button
              [rounded]="true"
              [text]="true"
              (click)="onImageClick($event, overlaypanel)"
              icon="pi pi-ellipsis-v"
              (keyup.enter)="onImageClick($event, overlaypanel)"
              tabindex="0"
            />
          }
          <p-overlayPanel #overlaypanel>
            <ul class="overlay-container">
              @for (action of tableConfigData.actionItems; track action; let i = $index) {
                @if (checkVisibilityByPermission(action.permission || null)) {
                  <li
                    (click)="onLinkClick(item, action.value)"
                    (keyup.enter)="dummyFunction()"
                    class="action-item"
                    [ngClass]="{ 'custom-disabled': action.disabled }"
                  >
                    <div class="icon">
                      @if (action.icon) {
                        <i [class]="action.icon"></i>
                      } @else {
                        <img [src]="action.img" alt="missing" width="20px" height="20px" />
                      }
                    </div>
                    <div class="label">{{ action.label }}</div>
                  </li>
                }
              }
            </ul>
          </p-overlayPanel>
        </td>
      } @else {
        <td colspan="100" class="height-adjustment-row"></td>
      }
    </tr>
    <tr class="extra"></tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      @if (noRecordFoundTemplate) {
        <td colspan="100" class="no-record-found">
          <ng-container [ngTemplateOutlet]="noRecordFoundTemplate"></ng-container>
        </td>
      } @else {
        <td colspan="100" class="no-record-found">No record found.</td>
      }
    </tr>
  </ng-template>
</p-table>

<!-- pagination -->
<div
  class="flex align-items-center justify-content-end"
  style="display: flex; align-items: center; justify-content: flex-end"
>
  <div>
    Showing {{ skipItems + 1 }} to
    {{ CollectionSize > pageSize + skipItems ? pageSize + skipItems : CollectionSize }} of
    {{ CollectionSize }} entries
  </div>
  <div class="card" style="display: flex; justify-content: flex-end">
    <p-paginator
      locale="en-US"
      (onPageChange)="onPageChange($event)"
      [first]="skipItems"
      [rows]="pageSize"
      [totalRecords]="CollectionSize"
      [rowsPerPageOptions]="rowsPerPageOptions"
    />
  </div>
</div>
<!-- /pagination -->

<!-- default template for all with ngswitch -->
<ng-template #value let-item="item" let-column="column">
  <ng-container [ngSwitch]="column.displayType">
    <!-- to display value as it is -->
    <ng-container *ngSwitchCase="displayTypes.value">
      <span>{{ item[column?.name] ? item[column?.name] : '-' }}</span>
    </ng-container>

    <!-- values where we need click operation -->
    <ng-container *ngSwitchCase="displayTypes.link">
      <span class="tableLink">
        <a tabindex="0" (click)="onLinkClick(item, column.action)" (keyup.enter)="dummyFunction()">
          {{ item[column?.name] }}
        </a>
      </span>
    </ng-container>

    <!-- to display % sign with value -->
    <ng-container *ngSwitchCase="displayTypes.completionPercentage">
      <span>{{ item[column?.name] ? item[column?.name] + ' %' : '-' }}</span>
    </ng-container>

    <ng-container *ngSwitchCase="displayTypes.nestedValue">
      <span>{{
        item[column?.nestedProperty] ? item[column?.nestedProperty][column?.name] : '-'
      }}</span>
    </ng-container>

    <ng-container *ngSwitchCase="displayTypes.countryFlag">
      <img
        src="assets/flags/flag_placeholder.png"
        alt="_"
        [class]="'flag flag-' + (item?.country?.code && item.country.code.toLowerCase())"
      />

      <span>{{
        item[column?.nestedProperty] ? item[column?.nestedProperty][column?.name] : ''
      }}</span>
    </ng-container>

    <ng-container *ngSwitchCase="displayTypes.status">
      <div [style]="{ 'text-align': 'center' }" class="full-width">
        <div [class]="getProjectStatusItemClass(item[column.name])">{{ item[column.name] }}</div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="displayTypes.genericStatus">
      <div>
        <div [class]="item[column.key]">{{ item[column.name] }}</div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="displayTypes.taskStatus">
      <div
        [style]="{ 'text-align': 'center' }"
        class="full-width"
        [class]="getStatusItemClass(item[column.name])"
      >
        {{ item[column.name] | titlecase }}
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="displayTypes.taskType">
      <div
        [style]="{ 'text-align': 'center' }"
        class="full-width"
        [class]="getTaskTypeClassKey(item[column.name].toLowerCase())"
      >
        {{ item[column.name] | titlecase }}
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="displayTypes.claimType">
      <div
        [style]="{ 'text-align': 'center' }"
        class="full-width"
        [class]="getClaimTypeClass(item[column.name])"
      >
        {{ item[column.name] | titlecase }}
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="displayTypes.priority">
      <div>
        <img
          [src]="getPriorityIcon(item[column.name])"
          alt="priority"
          [pTooltip]="item[column.name] | titlecase"
          tooltipPosition="top"
        />
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="displayTypes.users">
      <div>
        <app-overlap-avatar [users]="item[column.name]" />
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="displayTypes.task">
      <img
        *ngIf="item?.is_recurrent_task"
        src="assets/icons/recurrence.svg"
        alt="recurrence"
        srcset=""
      />
    </ng-container>
    <ng-container *ngSwitchCase="displayTypes.invoiceStatus">
      <div
        [style]="{ 'text-align': 'center' }"
        class="full-width"
        [class]="getInvoiceStatusStyleKey(item[column.name])"
      >
        {{ item[column.name] | titlecase }}
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="displayTypes.invoiceType">
      <div
        [style]="{ 'text-align': 'center' }"
        class="full-width"
        [class]="getInvoiceTypeStyleKey(item[column.name])"
      >
        {{ item[column.name] | titlecase }}
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="displayTypes.amount">
      @if (item[column?.name]) {
        <span>{{ item.currency.symbol }}</span
        ><span>{{ item[column?.name] | number: '1.0-2' }}</span>
      } @else {
        <span>-</span>
      }
    </ng-container>

    <ng-container *ngSwitchCase="displayTypes.limitedCharacters">
      <span style="text-overflow: ellipsis">{{
        item[column?.name] ? limitedCharacters(item[column?.name], 28) : '-'
      }}</span>
    </ng-container>

    <ng-container *ngSwitchCase="displayTypes.user">
      <p-button
        [text]="true"
        tabIndex="0"
        (click)="onLinkClick(item, column.action)"
        (keyup.enter)="dummyFunction()"
        class="d-flex align-items-center g-8 user-column"
        [ngStyle]="{ gap: '8px' }"
        styleClass="user-column"
        tabindex="0"
      >
        <app-avatar
          [name]="item.first_name + ' ' + item.last_name"
          [image]="item.profile_picture?.document"
          [height]="35"
          [width]="35"
        />
        <a>{{ item.first_name + ' ' + item.last_name }}</a>
      </p-button>
    </ng-container>

    <ng-container *ngSwitchCase="displayTypes.userRole">
      <div class="card flex justify-content-center pr-15">
        <p-dropdown
          [options]="userGroups"
          [(ngModel)]="item.user_role"
          optionLabel="name"
          placeholder="Select User Role"
          (onChange)="onSelectUserRole($event, item.id, item.username)"
        >
          <ng-template pTemplate="selectedItem">
            <div class="flex align-items-center gap-2">
              <div>{{ item.user_role.name }}</div>
            </div>
          </ng-template>
          <ng-template let-role pTemplate="item">
            <div class="align-items-center gap-2 p-5">
              <div class="role-title">{{ role.name }}</div>
              <div class="role-description">{{ role.description }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="displayTypes.capacity">
      <span>{{ item[column?.name] ? (item[column?.name] | number: '1.0-2') + ' MW' : '-' }}</span>
    </ng-container>
  </ng-container>
</ng-template>
