export enum TextFilterModeName {
  CONTAINS = 'Contains',
  STARTS_WITH = 'Starts With',
  ENDS_WITH = 'Ends With',
}

export enum NumericFilterModeName {
  EQUALS = 'Equals',
  GREATER_THAN_AND_EQUALS_TO = 'Greater than equals to',
  LESS_THAN_AND_EQUALS_TO = 'Less than equals to',
}

export enum TextFilterModeKey {
  CONTAINS = 'icontains',
  STARTS_WITH = 'istartswith',
  ENDS_WITH = 'iendswith',
}

export enum NumericFilterModeKey {
  EQUALS = 'equals',
  GREATER_THAN_AND_EQUALS_TO = 'gte',
  LESS_THAN_AND_EQUALS_TO = 'lte',
}

export enum ColumnFilterType {
  TEXT = 'text',
  NUMERIC = 'numeric',
  DATE = 'date',
  CUSTOM = 'custom',
}

export const FilterModesNameKeyMappings = {
  [NumericFilterModeName.EQUALS]: NumericFilterModeKey.EQUALS,
  [NumericFilterModeName.GREATER_THAN_AND_EQUALS_TO]:
    NumericFilterModeKey.GREATER_THAN_AND_EQUALS_TO,
  [NumericFilterModeName.LESS_THAN_AND_EQUALS_TO]: NumericFilterModeKey.LESS_THAN_AND_EQUALS_TO,
  [TextFilterModeName.CONTAINS]: TextFilterModeKey.CONTAINS,
  [TextFilterModeName.STARTS_WITH]: TextFilterModeKey.STARTS_WITH,
  [TextFilterModeName.ENDS_WITH]: TextFilterModeKey.ENDS_WITH,
};
