import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListResponse } from '../../shared/interfaces/common.interface';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  constructor(private http: HttpClient) {}

  getTableData(endPoint: string, params: HttpParams): Observable<ListResponse> {
    return this.http.get<ListResponse>(endPoint, {
      params: params,
    });
  }
}
