import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import {
  CustomFiltersData,
  DataTableConfig,
  TableActions,
  TableColumnDisplay,
  TableSignals,
} from '../../interfaces/table';
import { CommonModule } from '@angular/common';
import { TableService } from '../../../core/services/table.service';
import { PaginatorModule, PaginatorState } from 'primeng/paginator';
import { HttpParams } from '@angular/common/http';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { RouterModule } from '@angular/router';
import { MenuModule } from 'primeng/menu';
import { noop } from 'rxjs';
import { ParseFiltersService } from '@Services/filters/parse-filters.service';
import {
  getClaimTypeClass,
  getInvoiceStatusStyleKey,
  getInvoiceTypeStyleKey,
  getProjectStatusItemClass,
  getStatusItemClass,
  getTaskTypeClass,
} from '@shared/utils/common-utils';
import { ChipModule } from 'primeng/chip';
import { OverlapAvatarComponent } from '../overlap-avatar/overlap-avatar.component';
import { ToastMessageService } from '@Services/toast-message/toast-message.service';
import { AllGroupPermissions } from '@shared/enums/all-group-permissions/permissions';
import { PermissionService } from '@Services/permission/permission.service';
import { DEFAULT_PAGE_SIZE } from '@constants/common.const';
import { get, isArray } from 'lodash';
import { SelectedUserGroup, UserRole } from '@shared/interfaces';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { AvatarComponent } from '../avatar/avatar.component';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { CustomFiltersComponent } from './custom-filters/custom-filters.component';

export interface ListingResponse {
  count: number;
  results: unknown[];
}

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [
    TableModule,
    CommonModule,
    PaginatorModule,
    OverlayPanelModule,
    ButtonModule,
    RouterModule,
    MenuModule,
    ChipModule,
    OverlapAvatarComponent,
    AvatarComponent,
    CalendarModule,
    FormsModule,
    CustomFiltersComponent,
  ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
})
export class TableComponent<V> implements OnInit {
  @ContentChild('noRecordFoundTemplate') noRecordFoundTemplate?: TemplateRef<unknown>;

  @Input()
  set tableConfig(data: DataTableConfig<V>) {
    this.tableConfigData = data;
    this.skipItems = 0; // reset page number on filter/sort change
    this.getTableData(0, this.defaultPageSize);
  }

  @Input() userGroups: UserRole[];

  selectedGroup: SelectedUserGroup;

  @Output() tableSignals: EventEmitter<TableSignals<V>> = new EventEmitter<TableSignals<V>>();

  @Output() totalCount = new EventEmitter<number>();

  @Output() selectedUserGroup = new EventEmitter<SelectedUserGroup>();

  @Output() getTableDataOutput = new EventEmitter<HttpParams>();

  @Input() set inputData(res: ListingResponse) {
    this.handleListingResponse(res);
  }

  tableConfigData: DataTableConfig<V>;

  tableData: V[];

  tableDataResp: V[]; // to store the response data required for reset if data on table is changed

  displayTypes = TableColumnDisplay;

  currentPageNo = 1;

  skipItems = 0;

  CollectionSize = 0;

  pageSize = DEFAULT_PAGE_SIZE;

  tableActions = TableActions;

  loading: boolean;

  filtersData: TableLazyLoadEvent | null = null;

  customFilterData: CustomFiltersData = {};

  isActionDropdownVisible = false;

  getStatusItemClass = getStatusItemClass;

  getTaskTypeClassKey = getTaskTypeClass;

  getClaimTypeClass = getClaimTypeClass;

  getInvoiceStatusStyleKey = getInvoiceStatusStyleKey;

  getInvoiceTypeStyleKey = getInvoiceTypeStyleKey;

  heightAdjuster = 'height-adjuster';

  rowsPerPageOptions: number[] = [];

  constructor(
    private readonly tableService: TableService,
    private readonly parseFiltersService: ParseFiltersService,
    private readonly toastMessageService: ToastMessageService,
    private readonly permissionService: PermissionService,
  ) {
    this.loading = false;
  }

  ngOnInit() {
    this.pageSize = this.defaultPageSize;
    const defaultOptions = [25, 50, 75, 100];
    if (this.defaultPageSize) {
      this.rowsPerPageOptions = [...new Set([...defaultOptions, this.defaultPageSize])].sort(
        (a, b) => a - b,
      );
    } else {
      this.rowsPerPageOptions = defaultOptions;
    }
  }

  public getTableData(page?: number | null, pageSize?: number | null) {
    if (!this.tableConfigData) return;
    let params = new HttpParams();

    params = params.append('page', page === 0 ? 1 : (page ?? 1));
    params = params.append(
      'page_size',
      pageSize === 0 ? this.defaultPageSize : (pageSize ?? this.pageSize),
    );

    if (this.tableConfigData?.userExternalApiData) {
      this.getTableDataOutput.emit(params);
      return;
    }

    if (this.filtersData?.sortField) {
      params = params.append(
        'order_by',
        `${this.filtersData.sortOrder === -1 ? '-' : ''}${this.filtersData.sortField}`,
      );
    }
    if (this.filtersData) {
      params = this.parseFiltersService.populateFilters(params, this.filtersData);
    }
    if (this.customFilterData) {
      params = this.parseFiltersService.populateFilters(params, { filters: this.customFilterData });
    }
    // Add additional params if any in table config
    if (this.tableConfigData?.additionalParams) {
      this.tableConfigData.additionalParams?.forEach((param) => {
        params = params.append(param.key, param.value);
      });
    }
    this.toggleLoading();

    this.tableService.getTableData(this.tableConfigData?.endPoint, params).subscribe({
      next: (res: ListingResponse) => {
        this.handleListingResponse(res);
        this.toggleLoading();
      },
      error: (httpError) => {
        this.toggleLoading();
        this.toastMessageService.showValidationErrors(
          httpError.error,
          httpError?.error?.error_message,
        );
      },
    });
  }

  handleListingResponse(res: ListingResponse) {
    if (!res) return;
    this.totalCount.emit(res.count);
    if (this.tableConfigData.formatData) {
      this.tableData = this.tableConfigData.formatData(res.results as V[]);
      this.tableDataResp = this.tableData.map((item) => ({ ...item }));
    } else {
      this.tableData = res.results as V[];
    }
    if (
      this.tableData &&
      this.tableData.length > 0 &&
      !this.tableData.find((item) => get(item, 'key') === this.heightAdjuster)
    ) {
      this.tableData.push({ key: this.heightAdjuster } as V);
    } // Added an empty row to adjust row height if table has less data than table min height
    this.CollectionSize = res.count;
  }

  get getTableStyle() {
    return {
      'min-width': this.tableConfigData.fixedMinWidth
        ? this.tableConfigData.fixedMinWidth
        : '50rem',
      'overflow-x': this.tableConfigData.fixedWidth ? 'auto' : 'hidden',
      'min-height': '75vh',
      ...(this.tableConfigData.fixedWidth && { 'table-layout': 'fixed' }),
    };
  }

  toggleLoading() {
    this.loading = !this.loading;
  }

  //will be used for redirection
  onLinkClick(item: V, action: TableActions) {
    this.tableSignals.emit({ action: action, data: item });
  }

  onPageChange(event: PaginatorState) {
    this.currentPageNo = event.page ? event.page + 1 : 1;
    this.skipItems = event.first ?? 0;
    this.pageSize = event.rows ?? this.defaultPageSize;
    this.getTableData(this.currentPageNo, this.pageSize);
  }

  onImageClick(event: Event, overlaypanel: OverlayPanel) {
    overlaypanel.toggle(event);
  }

  loadTableData(data: TableLazyLoadEvent) {
    this.filtersData = data;
    this.skipItems = 0; // reset page number on filter/sort change
    this.getTableData();
  }

  onCustomFilterChangeHandler(data: CustomFiltersData) {
    this.customFilterData = { ...this.customFilterData, ...data };
    this.skipItems = 0; // reset page number on filter/sort change
    this.getTableData();
  }

  reloadCurrentPage() {
    this.getTableData(this.currentPageNo, this.pageSize);
  }

  resetData() {
    // will reset the data to initial data tableDataResp
    this.tableData = this.tableDataResp.map((item) => ({ ...item }));
  }

  getPriorityIcon(priorityPayloadText: string): string {
    switch (priorityPayloadText.toLowerCase()) {
      case 'high':
        return '/assets/icons/highest.svg';

      case 'medium':
        return '/assets/icons/medium.svg';

      case 'low':
        return '/assets/icons/lowest.svg';

      default:
        return '';
    }
  }

  checkVisibilityByPermission(
    permission: AllGroupPermissions | AllGroupPermissions[] | null,
  ): boolean {
    if (!permission) return true; // if permission not passed return true
    if (isArray(permission)) {
      return permission.some((perm) => this.permissionService.hasPermission(perm));
    }
    return this.permissionService.hasPermission(permission);
  }

  get dropdownVisibility(): false {
    let visibility = false;
    this.tableConfigData.actionItems?.map((item) => {
      if (
        (item.permission && this.checkVisibilityByPermission(item.permission)) ||
        !item.permission
      ) {
        visibility = true;
      }
    });
    return visibility;
  }

  onSelectUserRole(event: DropdownChangeEvent, userId: string, username: string): void {
    const selectedUserGroup: SelectedUserGroup = {
      id: event.value.id,
      name: event.value.name,
      description: event.value.description,
      userId: userId,
      username: username,
    };
    this.tableSignals.emit({ action: TableActions.changeRole, data: selectedUserGroup as V });
  }

  dummyFunction = noop;

  getProjectStatusItemClass = getProjectStatusItemClass;

  limitedCharacters(text: string, limit: number): string {
    return text.length > limit ? text.slice(0, limit) + '...' : text;
  }

  get defaultPageSize(): number {
    return this.tableConfigData?.defaultPageSize ?? DEFAULT_PAGE_SIZE;
  }
}
