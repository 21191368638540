<div>
  <p-button
    [id]="'filter-' + filterConfig.fieldName"
    icon="pi pi-filter"
    (onClick)="op.toggle($event)"
    (keyup.enter)="$event.stopPropagation(); op.toggle($event)"
    [text]="true"
    [rounded]="true"
    [styleClass]="filteredValue ? 'custom-filter-button-active' : 'custom-filter-button'"
    tabindex="0"
  ></p-button>
  <p-overlayPanel styleClass="custom-filter-overlay" #op>
    <div class="match-dropdown mb-15">
      <p-dropdown
        #filtersDropdown
        [(ngModel)]="matchMode"
        [options]="filterConfig.options"
        [showClear]="false"
        placeholder="Select filter"
        class="full-width"
      >
        <ng-template pTemplate="selectedItem">
          <div class="selected-status-chip">
            {{ filtersDropdown.value }}
          </div>
        </ng-template>
        <ng-template let-filter pTemplate="item"> {{ filter }} </ng-template>
      </p-dropdown>
    </div>
    <div class="">
      <div>
        <ng-container #vcr></ng-container>
      </div>
      <div class="d-flex justify-content-space-between mt-15 full-width">
        <p-button
          styleClass="clear-filter p-button-outlined"
          label="Clear"
          (click)="clearFilter(op)"
          (keyup.enter)="clearFilter(op)"
          tabindex="0"
        ></p-button>
        <p-button
          styleClass="apply-filter"
          label="Apply"
          (click)="applyFilter(op)"
          (keyup.enter)="applyFilter(op)"
          tabindex="0"
        ></p-button>
      </div>
    </div>
  </p-overlayPanel>
</div>
