import { DateFilterComponent } from '@shared/components/table/custom-filters/date-filter/date-filter.component';
import { AllGroupPermissions } from '@shared/enums/all-group-permissions/permissions';
import {
  ColumnFilterType,
  NumericFilterModeName,
  TextFilterModeName,
} from '@shared/enums/filters/table-filters';

export interface DataTableConfig<V> {
  endPoint: string;
  columns: DataTableColumn[];
  sortBy: string;
  fixedWidth?: boolean;
  fixedMinWidth?: string;
  formatData?(data: V[]): V[];
  showFilters?: boolean;
  showActions?: boolean;
  userExternalApiData?: boolean;
  defaultPageSize?: number | null;
  actionItems?: {
    label: string;
    value: TableActions;
    permission?: AllGroupPermissions | AllGroupPermissions[] | null;
    icon?: string | null; // either pass icon or image if both provided icon has priority
    img?: string | null;
    disabled?: boolean;
  }[];
  showCheckboxes?: boolean;
  additionalParams?: { key: string; value: string | number | boolean }[];
}

export interface DataTableFilterAndAction {
  label: string;
  value: string;
}

export interface DataTableFilterConfig {
  options: TextFilterModeName[] | NumericFilterModeName[]; // SelectItem<any>;
  selectedFilter: TextFilterModeName | NumericFilterModeName;
  placeholder: string;
  fieldName: string;
  type: ColumnFilterType;
  showApplyButton?: boolean;
  component?: DateFilterComponent | null;
  customComponentInputs?: Record<string, unknown>;
}

export type CustomFiltersData = Record<string, { value: unknown; matchMode: string }[]>;

export interface DataTableColumn {
  name: string;
  key?: string | null;
  title: string;
  width?: string;
  align?: 'left' | 'right' | 'center';
  displayType: TableColumnDisplay;
  action?: TableActions;
  nestedProperty?: string;
  hasColumnFilters: boolean;
  sortConfig?: {
    sortKey: string;
  };
  sortable?: boolean;
  filterConfig?: DataTableFilterConfig;
}

export interface TableSignals<T> {
  action: TableActions;
  data: T;
}

export enum TableColumnDisplay {
  value = 'value',
  link = 'link',
  status = 'status',
  nestedValue = 'nestedValue',
  countryFlag = 'countryFlag',
  taskStatus = 'taskStatus',
  taskType = 'taskType',
  claimType = 'claimType',
  completionPercentage = 'completionPercentage',
  priority = 'priority',
  users = 'users',
  task = 'task',
  invoiceStatus = 'invoiceStatus',
  invoiceType = 'invoiceType',
  amount = 'amount',
  userRole = 'userRole',
  user = 'user',
  limitedCharacters = 'limitedCharacters',
  capacity = 'capacity',
  genericStatus = 'genericStatus',
}

export enum TableActions {
  viewProjectDetails = 'viewProjectDetails',
  openEditMode = 'openEditMode',
  deleteItem = 'deleteItem',
  archive = 'archive',
  exportToPDF = 'exportToPDF',
  changeRole = 'changeRole',
}
