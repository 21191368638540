import { NgComponentOutlet } from '@angular/common';
import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CustomFiltersData, DataTableFilterConfig } from '@shared/interfaces/table';
import { ButtonModule } from 'primeng/button';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { FilterComponent } from './FilterComponentClass';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'app-custom-filters',
  standalone: true,
  imports: [ButtonModule, OverlayPanelModule, FormsModule, NgComponentOutlet, DropdownModule],
  templateUrl: './custom-filters.component.html',
  styleUrl: './custom-filters.component.scss',
})
export class CustomFiltersComponent<V> implements OnInit {
  @ViewChild('vcr', { static: true, read: ViewContainerRef })
  vcr!: ViewContainerRef;

  @Input() filterConfig: DataTableFilterConfig;

  // to handle apply filter button click
  @Output() onFilterChangeHandler = new EventEmitter<CustomFiltersData>();

  dynamicComponent: Type<FilterComponent<V>> | null = null;

  filteredValue: V | null = null;

  matchMode: string | null = null;

  componentRef: ComponentRef<FilterComponent<V>>;

  ngOnInit(): void {
    this.matchMode = this.filterConfig.selectedFilter as string;
    this.dynamicComponent =
      (this.filterConfig.component as unknown as Type<FilterComponent<V>>) || null;

    this.componentRef = this.vcr.createComponent(this.dynamicComponent);
    this.componentRef.instance.filterDataChange.subscribe(this.onFilterChange.bind(this));
    this.componentRef.instance.placeholder = this.filterConfig.placeholder ?? null;
    this.matchMode = this.filterConfig.selectedFilter as string;
    this.componentRef.changeDetectorRef.detectChanges();
  }

  onFilterChange(value: V | null): void {
    this.filteredValue = value;
  }

  applyFilter(overlay: OverlayPanel): void {
    this.onFilterChangeHandler.emit({
      [this.filterConfig.fieldName]: [
        {
          value: this.filteredValue,
          matchMode: this.matchMode,
        },
      ],
    } as CustomFiltersData);
    overlay.hide();
  }

  clearFilter(overlay: OverlayPanel): void {
    this.filteredValue = null;
    this.componentRef.instance.value = null;
    this.onFilterChangeHandler.emit({ [this.filterConfig.fieldName]: [] });
    overlay.hide();
  }
}
