import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FilterModesNameKeyMappings,
  NumericFilterModeName,
} from '@shared/enums/filters/table-filters';
import { get } from 'lodash';
import { TableLazyLoadEvent } from 'primeng/table';

@Injectable({
  providedIn: 'root',
})
export class ParseFiltersService {
  populateFilters(params: HttpParams, data: TableLazyLoadEvent) {
    let filterKeys: string[] = [];
    if (data.filters) {
      filterKeys = Object.keys(data.filters);
    }

    filterKeys.forEach((key: string) => {
      const modeKey = get(data, `filters[${key}][0].matchMode`) ?? '';
      const value = get(data, `filters[${key}][0].value`) ?? null;
      if (!value) {
        return;
      }
      let modeKeyQuery = `__${get(FilterModesNameKeyMappings, modeKey)}`;
      if (modeKey === (`${NumericFilterModeName.EQUALS}` as string)) {
        modeKeyQuery = '';
      }

      params = params.append(`${key}${modeKeyQuery}`, value);
    });
    return params;
  }
}
